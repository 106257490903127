import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Import images
const importAll = (r) => r.keys().map(r);
const allImages = importAll(require.context('../images/memorise', false, /\.(png|jpe?g|svg)$/));

// Define the filenames of images that should appear at the end
const lastImageNames = [
  'IMG-20241025-WA0026',
  'IMG-20241025-WA0048'
];

// Sort images to ensure specific images appear at the end
const sortedImages = allImages.sort((a, b) => {
  const aIsLast = lastImageNames.some(name => a.includes(name));
  const bIsLast = lastImageNames.some(name => b.includes(name));
  
  if (aIsLast && !bIsLast) return 1;  // a should come after b
  if (!aIsLast && bIsLast) return -1; // b should come after a
  return 0; // maintain relative order for other images
});

// Create moments array with sorted images
const moments = sortedImages.map((image, index) => ({
  id: index + 1,
  image: image,
  description: `Memorable moment ${index + 1}`,
  isSpecial: lastImageNames.some(name => image.includes(name))
}));

const CollageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const CollageItem = styled(motion.div)`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1 / 1;
`;

const CollageImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  ${CollageItem}:hover & {
    transform: translateY(0);
  }
`;

const Memories = () => {
  return (
    <Container fluid className="mt-5">
      <motion.h1 
        className="text-center mb-5"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Our Memorable Moments
      </motion.h1>
      <CollageWrapper>
        {moments.map((moment) => (
          <CollageItem
            key={moment.id}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className={moment.isSpecial ? 'special-image' : ''}
          >
            <CollageImage 
              src={moment.image} 
              alt={moment.description} 
            />
            <ImageOverlay>
              <p>{moment.description}</p>
            </ImageOverlay>
          </CollageItem>
        ))}
      </CollageWrapper>
    </Container>
  );
};

export default Memories;
