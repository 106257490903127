import React, { useState } from 'react';
import { Container, Form, Button, Image, ListGroup } from 'react-bootstrap';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import heroImage from '../images/hero imsge frenchise page.jpg';

// Import product images
import dFridgeImage from '../images/f images/D fridge.jpg';
import counterImage from '../images/f images/counter.jpg';
import teaJarImage from '../images/f images/.TEA JAR (4 LITER).jpg';
import grillImage from '../images/f images/grill .jpg';
import ledBoard1Image from '../images/f images/LED BOARD (2).jpg';
import ledBoard2Image from '../images/f images/LED BOARD.jpg';
import steelTableImage from '../images/f images/ONE STEEL TABLE.jpg';
import sujataMixtureImage from '../images/f images/SUJATA MIXTURE.jpg';
import doubleBurnerImage from '../images/f images/DOUBLE BURNER FOR TEA.jpg';

const productsWithImages = [
  { name: 'D Fridge', image: dFridgeImage },
  { name: 'Counter', image: counterImage },
  { name: 'Tea Jar (4 Liter)', image: teaJarImage },
  { name: 'Sandwich Griller', image: grillImage },
  { name: 'LED Board', image: ledBoard1Image },
  { name: 'LED Board (Alternate)', image: ledBoard2Image },
  { name: 'Steel Table', image: steelTableImage },
  { name: 'Sujata Mixture', image: sujataMixtureImage },
  { name: 'Double Burner for Tea', image: doubleBurnerImage },
];

const allProducts = [
  'D Fridge',
  'Counter',
  'Tea Jar (4 Liter)',
  'Sandwich Griller',
  'LED Board',
  'LED Board (Alternate)',
  'Steel Table',
  'Sujata Mixture',
  'Double Burner for Tea',
  'ONE CUP STAND STEEL TABLE',
  'COMPLETE MARKETING',
  'TRAINING FOR TEA',
  'TRAINING FOR SHAKE, COFFEE, FASTFOOD & MORE',
  'Mewadi T-shirts',
  'Sitting arrangements',
  'All utensils (45 items)',
  '4000 CUP TEA MASALA',
  'Mewadi cups',
];

const CollageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding: 20px;
`;

const CollageItem = styled.div`
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 10px;
`;

const CollageImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  ${CollageItem}:hover & {
    transform: translateY(0);
  }
`;

const Franchise = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', city: '', space: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `Hello, I am interested in the Mewadi Chah franchise. Here are my details:\n\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nSpace Available: ${formData.space} sq ft`;
    const whatsappUrl = `https://wa.me/918302425037?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container fluid className="p-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Image src={heroImage} fluid className="w-100" style={{ height: '400px', objectFit: 'cover' }} />
      </motion.div>

      <Container className="mt-5">
        <h1 className="text-center mb-5">Mewadi Chah Franchise Opportunity</h1>

        <h2 className="text-center mt-5 mb-4">Products Included in Franchise</h2>
        <CollageWrapper>
          {productsWithImages.map((product, index) => (
            <CollageItem key={index}>
              <CollageImage src={product.image} alt={product.name} />
              <ImageOverlay>
                <p>{product.name}</p>
              </ImageOverlay>
            </CollageItem>
          ))}
        </CollageWrapper>

        <h3 className="mt-5 mb-3">All Products and Services</h3>
        <ListGroup className="mb-5">
          {allProducts.map((product, index) => (
            <ListGroup.Item key={index}>{product}</ListGroup.Item>
          ))}
        </ListGroup>

        <h2 className="text-center mt-5 mb-4">Franchise Application</h2>
        <Form onSubmit={handleSubmit} className="card p-4">
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" name="phone" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" name="city" onChange={handleChange} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Space Available (sq ft)</Form.Label>
            <Form.Control type="number" name="space" onChange={handleChange} required />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">Submit Application</Button>
        </Form>

        <div className="text-center mt-5 mb-5">
          <h2>Franchise Cost: ₹299,999</h2>
          <p>Invest in your future with Mewadi Chah today!</p>
        </div>
      </Container>
    </Container>
  );
};

export default Franchise;
