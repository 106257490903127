// src/components/Products.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css';  
import alooPatties from '../images/aloo patties.webp';
import burger from '../images/burger menu item.jpg';
import cappuccino from '../images/cappuccino coffee menu item.webp';
import cheesePatties from '../images/cheese patties menu items.avif';
import cheeseSandwich from '../images/cheese sandwich menu item.jpg';
import coldCoffee from '../images/cold cofey menu.jpg';
import hotCoffee from '../images/hot coffe menu item.webp';
import lemonTea from '../images/lamon tea menu item.png';
import maggi from '../images/meggie menu items.jpg';
import sandwich from '../images/sandwich menu item.webp';

export const products = [
  { id: 1, name: 'Aloo Patties', image: alooPatties, description: 'Crispy potato patties with a blend of spices.' },
  { id: 2, name: 'Burger', image: burger, description: 'Juicy patty with fresh veggies in a soft bun.' },
  { id: 3, name: 'Cappuccino', image: cappuccino, description: 'Rich espresso topped with creamy foam.' },
  { id: 4, name: 'Cheese Patties', image: cheesePatties, description: 'Crispy patties filled with gooey cheese.' },
  { id: 5, name: 'Cheese Sandwich', image: cheeseSandwich, description: 'Melted cheese between toasted bread slices.' },
  { id: 6, name: 'Cold Coffee', image: coldCoffee, description: 'Refreshing iced coffee perfect for hot days.' },
  { id: 7, name: 'Hot Coffee', image: hotCoffee, description: 'Aromatic hot coffee to kickstart your day.' },
  { id: 8, name: 'Lemon Tea', image: lemonTea, description: 'Zesty lemon-infused tea for a refreshing break.' },
  { id: 9, name: 'Maggi', image: maggi, description: 'Quick and tasty noodles with a special Mewadi twist.' },
  { id: 10, name: 'Sandwich', image: sandwich, description: 'Fresh vegetables and fillings between soft bread.' },
];

const ProductCard = ({ product }) => (
  <Card className="h-100 product-card">
    <Card.Img variant="top" src={product.image} alt={product.name} />
    <Card.Body className="d-flex flex-column">
      <Card.Title>{product.name}</Card.Title>
      <Card.Text>{product.description}</Card.Text>
      <Link to={`/products/${product.id}`} className="btn btn-primary mt-auto">
        View Details
      </Link>
    </Card.Body>
  </Card>
);

const Products = () => {
  return (
    <Container className="products-container">
      <h2 className="products-heading">Our Products</h2>
      <Row>
        {products.map((product) => (
          <Col key={product.id} md={4} className="mb-4">
            <ProductCard product={product} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Products;
