import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Import hero image
import heroImage from '../animations/about page hero image.gif';
// Import founder and CMO images
import founderImage from '../images/founder-image.jpg';
import cmoImage from '../images/cmo-image.jpg';

// Styled Components
const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  min-height: 500px;
  overflow: hidden;
  margin-bottom: 4rem;
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8);
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2;
  width: 80%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  
  h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const StyledSection = styled(motion.section)`
  margin: 5rem 0;
  padding: 3rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  
  h2 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 1rem;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 4px;
      background: #ffd700;
      border-radius: 2px;
    }
  }

  ul {
    padding-left: 1.5rem;
    li {
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }
  }
`;

const StatsCard = styled.div`
  background: linear-gradient(135deg, #ffd700 0%, #ffa500 100%);
  color: white;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  margin: 1rem 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
  h3 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    font-size: 1.1rem;
    margin: 0;
  }
`;

const GoldenButton = styled(Button)`
  background: linear-gradient(145deg, #ffd700, #ffa500);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
  color: #000;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.4);
    color: #000;
  }
`;

const About = () => {
  return (
    <Container fluid className="p-0">
      <HeroSection>
        <HeroImage src={heroImage} alt="Mewadi Chah Hero" />
        <HeroContent>
          <motion.h1
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            About Mewadi Chah
          </motion.h1>
        </HeroContent>
      </HeroSection>

      <Container>
        {/* Founder Image Section */}
        <StyledSection>
          <h2>Meet Our Founder</h2>
          <Row className="align-items-center">
            <Col md={6}>
              <img 
                src={founderImage}
                alt="Gaurav Sarang - Founder & CEO"
                style={{ width: '100%', borderRadius: '15px' }} // Adjust styles as needed
              />
            </Col>
            <Col md={6}>
              <p style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>
                Gaurav Sarang, the visionary behind Mewadi Chah, has dedicated his life to bringing the authentic taste of Mewadi tea to tea lovers everywhere. His leadership and passion for quality have driven the success of our brand.
              </p>
            </Col>
          </Row>
        </StyledSection>

        {/* CMO Image Section */}
        <StyledSection>
          <h2>Meet Our CMO</h2>
          <Row className="align-items-center">
            <Col md={6}>
              <img 
                src={cmoImage}
                alt="Abhishek Jangid - CMO"
                style={{ width: '100%', borderRadius: '15px' }} // Adjust styles as needed
              />
            </Col>
            <Col md={6}>
              <p style={{ fontSize: '1.1rem', lineHeight: '1.8' }}>
                Abhishek Jangid, our Chief Management Officer, plays a crucial role in ensuring the smooth operation of our franchises and maintaining the high standards of Mewadi Chah.
              </p>
            </Col>
          </Row>
        </StyledSection>

        {/* Stats Section */}
        <Row className="my-5">
          {[{ number: '30+', text: 'Outlets Nationwide' }, { number: '15+', text: 'Cities Present' }, { number: '40L+', text: 'Cups Served Daily' }, { number: '60%', text: 'Profit Margin' }].map((stat, index) => (
            <Col md={3} key={index}>
              <StatsCard>
                <h3>{stat.number}</h3>
                <p>{stat.text}</p>
              </StatsCard>
            </Col>
          ))}
        </Row>

        <StyledSection>
          <h2>Our Mission and Vision</h2>
          <p>At Mewadi Chah, our mission is twofold: to introduce the rich flavors of traditional Mewadi tea to tea lovers worldwide, and to foster entrepreneurship in India by providing lucrative business opportunities. We envision a world where every tea enthusiast can enjoy the authentic taste of Mewadi tea, and where aspiring entrepreneurs can build successful businesses through our franchise model.</p>
        </StyledSection>

        <StyledSection>
          <h2>What Sets Us Apart</h2>
          <Row className="align-items-center">
            <Col md={12}>
              <ul>
                <li>A proven, profitable business model with 30+ successful franchises</li>
                <li>No royalty model, ensuring higher profits for our franchisees</li>
                <li>Average gross profit ranging from 15% to 60%</li>
                <li>Innovative "Safed safe model" with minimal wastage</li>
                <li>Comprehensive setup including 3D LED boards, double burners, and sitting arrangements</li>
                <li>Flexible space requirements from 100 to 200 sq. ft.</li>
              </ul>
            </Col>
          </Row>
        </StyledSection>

        <StyledSection>
          <h2>Our Achievements</h2>
          <p>We take pride in our growth and the positive impact we've had on the tea industry and entrepreneurship in India. Some of our notable achievements include:</p>
          <ul>
            <li>Expanding to over 30 outlets across 15+ cities in just a few years</li>
            <li>Successfully selling more than 30 profitable franchises</li>
            <li>Serving over 40 lakh cups of our signature tea and sandwiches daily</li>
            <li>Consistently maintaining high-quality standards across all our outlets</li>
          </ul>
        </StyledSection>

        <StyledSection>
          <h2>Join the Mewadi Chah Family</h2>
          <p>Whether you're a tea enthusiast or an aspiring entrepreneur, we invite you to be part of the Mewadi Chah success story. Experience the rich flavors of our traditional Mewadi tea or explore the exciting franchise opportunities we offer. Together, let's spread the aroma of success!</p>
          <GoldenButton 
            as="a"
            href="/franchise" 
            className="d-block mx-auto mt-4"
          >
            Explore Franchise Opportunities
          </GoldenButton>
        </StyledSection>
      </Container>
    </Container>
  );
};

export default About;
