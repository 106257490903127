// src/components/ProductDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { products } from './Products'; // Import the products array from Products.js

const ProductDetails = () => {
  const { id } = useParams();
  const product = products.find(p => p.id === parseInt(id));

  if (!product) return <p>Product not found</p>;

  return (
    <Container className="mt-5">
      <Row>
        <Col md={6}>
          <Image src={product.image} alt={product.name} fluid />
        </Col>
        <Col md={6}>
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <ListGroup variant="flush">
            <ListGroup.Item><strong>Ingredients:</strong> Various fresh ingredients</ListGroup.Item>
            <ListGroup.Item><strong>Nutritional Value:</strong> Balanced nutrition</ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetails;

