import React from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import './styles.css';

const SocialButton = ({ icon: Icon, href, label }) => (
  <Button
    variant="outline-primary"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="social-btn me-2 mb-2"
  >
    <Icon className="me-2" />
    {label}
  </Button>
);

const Contact = () => (
  <Container className="mt-5 pt-5">
    <Row>
      <Col lg={6}>
        <h2 className="mb-4">Contact Us</h2>
        <p>We'd love to hear from you! Reach out to us for any queries or feedback.</p>
        <Form className="contact-form">
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter your email" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={4} placeholder="Your message" required />
          </Form.Group>
          <Button variant="primary" type="submit" className="submit-btn">Send Message</Button>
        </Form>
      </Col>
      <Col lg={6} className="mt-4 mt-lg-0">
        <h3 className="mb-4">Get in Touch</h3>
        <div className="contact-info">
          <p><strong>Email:</strong> info@mewadichah.com</p>
          <p><strong>Phone:</strong> +91 8302425037</p>
          <p><strong>Address:</strong> 113 Kamla Nehru Nagar, Chopasni Road, Jodhpur, Rajasthan</p>
        </div>
        <div className="mt-4 social-links">
          <h4 className="mb-3">Connect With Us</h4>
          <SocialButton 
            icon={FaInstagram} 
            href="https://www.instagram.com/mewadi_chah_cafe/profilecard/?igsh=dnIwMmg0aWJoaHo4"
            label="Follow on Instagram"
          />
          <SocialButton 
            icon={FaFacebook} 
            href="https://www.facebook.com/profile.php?id=61567869504092"
            label="Like on Facebook"
          />
          <SocialButton 
            icon={FaWhatsapp} 
            href="https://wa.me/918302425037"
            label="Chat on WhatsApp"
          />
        </div>
      </Col>
    </Row>
  </Container>
);

export default Contact;
