import React, { useState } from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../animations/mewadi logo.gif';

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    setExpanded(false);
    navigate(path);
  };

  return (
    <Navbar 
      bg="white" 
      expand="lg" 
      fixed="top" 
      className="custom-navbar shadow-sm" 
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand 
          as={Link} 
          to="/" 
          onClick={() => setExpanded(false)}
        >
          <Image 
            src={logo} 
            alt="Mewadi Chah Logo" 
            height="40" 
            className="d-inline-block align-top me-2" 
          />
          Mewadi Chah
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          onClick={() => setExpanded(!expanded)} 
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as="span" onClick={() => handleNavClick('/')}>Home</Nav.Link>
            <Nav.Link as="span" onClick={() => handleNavClick('/about')}>About</Nav.Link>
            <Nav.Link as="span" onClick={() => handleNavClick('/products')}>Products</Nav.Link>
            <Nav.Link as="span" onClick={() => handleNavClick('/franchise')}>Franchise</Nav.Link>
            <Nav.Link as="span" onClick={() => handleNavClick('/memories')}>Memories</Nav.Link>
            <Nav.Link as="span" onClick={() => handleNavClick('/contact')}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
