import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import heroImage from '../images/MEWADI CHAH home page hero image.jpeg';
import './styles.css';
import mewadiBeverage from '../images/MEWADI CHAH home page hero image.jpeg';
import lemonTea from '../images/lamon tea menu item.png';
import coffee from '../images/hot coffe menu item.webp';
import masalaChai from '../images/Masala Chai.webp';

const Hero = () => {
  return (
    <div className="hero-section">
      <div className="hero-background">
        <img 
          src={heroImage} 
          alt="Mewadi Chah Hero"
          className="hero-image"
        />
        <div className="hero-overlay"></div>
      </div>
      <Container className="hero-content">
        <Row>
          <Col md={8} lg={6}>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="hero-text-container"
            >
              <h1 className="hero-title">Welcome to Mewadi Chah</h1>
              <p className="hero-subtitle">Experience the rich flavors of authentic Indian tea</p>
              <p className="hero-description">Discover our premium blends and join the Mewadi Chah family through our exciting franchising opportunities.</p>
              <Button 
                variant="warning" 
                size="lg" 
                href="/franchise"
                className="franchise-button golden-btn"
              >
                Get a Franchise
              </Button>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const TeaBlends = () => (
  <Container className="mt-5">
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <h2 className="mb-4 text-center">Our Unique Tea Blends</h2>
      <Row>
        {[
          {
            name: 'Mewadi Special',
            description: 'A unique blend of flavors that will tantalize your taste buds.',
            image: mewadiBeverage
          },
          {
            name: 'Lemon Tea',
            description: 'Refreshing citrus blend with premium tea leaves.',
            image: lemonTea
          },
          {
            name: 'Coffee',
            description: 'Rich and aromatic coffee brewed to perfection.',
            image: coffee
          },
          {
            name: 'Masala Chai',
            description: 'Traditional Indian spiced tea with authentic flavors.',
            image: masalaChai
          }
        ].map((beverage, index) => (
          <Col md={3} key={index}>
            <Card className="mb-4 beverage-card">
              <Card.Img 
                variant="top" 
                src={beverage.image} 
                alt={beverage.name}
                className="beverage-image"
              />
              <Card.Body>
                <Card.Title>{beverage.name}</Card.Title>
                <Card.Text>
                  {beverage.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </motion.div>
  </Container>
);

const FranchiseInfo = () => (
  <Container className="mt-5">
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.6 }}
    >
      <h2 className="mb-4 text-center">Why Franchise with Us?</h2>
      <Row>
        <Col md={3}>
          <h4>Proven Business Model</h4>
          <p>Our successful tea franchise model has been refined over years of experience, ensuring a smooth path to success.</p>
        </Col>
        <Col md={3}>
          <h4>Quality Products</h4>
          <p>We source the finest tea leaves and ingredients to ensure premium quality and consistent taste across all our outlets.</p>
        </Col>
        <Col md={3}>
          <h4>Comprehensive Support</h4>
          <p>From initial setup to ongoing operations, we provide full support to our franchisees, including training and marketing assistance.</p>
        </Col>
        <Col md={3}>
          <h4>Brand Recognition</h4>
          <p>Join a rapidly growing brand with a strong reputation for quality and authenticity in the tea industry.</p>
        </Col>
      </Row>
    </motion.div>
  </Container>
);

const Testimonials = () => (
  <Container className="mt-5">
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.8 }}
    >
      <h2 className="mb-4 text-center">What Our Customers Say</h2>
      <Row>
        {[
          { name: "Rahul S.", text: "The best chai I've ever had! Mewadi Chah is now my daily ritual." },
          { name: "Priya M.", text: "As a franchise owner, I'm impressed by the support and quality of products." },
          { name: "Amit K.", text: "The variety of tea blends keeps me coming back for more. Highly recommended!" }
        ].map((testimonial, index) => (
          <Col md={4} key={index}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Text>"{testimonial.text}"</Card.Text>
                <Card.Footer className="text-muted">- {testimonial.name}</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </motion.div>
  </Container>
);

const About = () => (
  <Container className="mt-5">
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 1 }}
    >
      <h2 className="mb-4 text-center">About Mewadi Chah</h2>
      <Row>
        <Col md={6}>
          <p>Mewadi Chah is more than just a tea brand; it's a celebration of India's rich tea culture. Founded with a passion for authentic flavors and a commitment to quality, we bring the finest tea experiences to our customers.</p>
        </Col>
        <Col md={6}>
          <p>Our journey began in the heart of Rajasthan, and today, we're proud to share our unique blends and traditional chai-making techniques with tea lovers across the country through our growing network of franchises.</p>
        </Col>
      </Row>
    </motion.div>
  </Container>
);

const Home = () => {
  return (
    <div className="home-page">
      <Hero />
      <TeaBlends />
      <FranchiseInfo />
      <Testimonials />
      <About />
    </div>
  );
};

export default Home;
