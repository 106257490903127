// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navbar';  // Import the Navbar component
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import ProductDetails from './components/ProductDetails';
import Contact from './components/Contact';
import Franchise from './components/Franchise';
import Memories from './components/Memories'; // Add this import

// Import styles in the correct order
import 'bootstrap/dist/css/bootstrap.min.css';  // Bootstrap CSS first
import './App.css';  // Then App.css
import './components/styles.css';  // Then component styles

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />  {/* Add the Navbar component here */}
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/memories" element={<Memories />} /> {/* Add this route */}
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
